import { ThemeProvider } from 'styled-components';
import { AppProps } from 'next/app';
import FacebookPixel from 'src/components/FacebookPixel/FacebookPixel';
import NextNProgress from 'nextjs-progressbar';
import { jackPreset } from 'theme/jack';
import { GlobalStyle } from 'src/components/GlobalStyle';
import '../assets/font-icons/styles.css';

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ThemeProvider theme={jackPreset}>
      <GlobalStyle />
      <FacebookPixel />
      <NextNProgress />
      <Component {...pageProps} />
    </ThemeProvider>
  );
}

export default MyApp;
