import {
  createSkeletonColorsTransition,
  fontPath,
  Theme,
  FontType,
} from '@shapegames/web-library-quick-draw-ui/dist/theme';

// DinPro will be used for Highlights
import DINBoldOTF from '@shapegames/web-library-quick-draw-ui/dist/assets/fonts/din/DINBold.otf';
import DINBlackTTF from '@shapegames/web-library-quick-draw-ui/dist/assets/fonts/din/DINBlack.ttf';

import ProximaNova from '@shapegames/web-library-quick-draw-ui/dist/assets/fonts/proximanova/Proxima_Nova.woff2';
import ProximaNovaBold from '@shapegames/web-library-quick-draw-ui/dist/assets/fonts/proximanova/Proxima_Nova_Bold.woff2';
import ProximaNovaExtrabold from '@shapegames/web-library-quick-draw-ui/dist/assets/fonts/proximanova/Proxima_Nova_Extrabold.woff2';

export const JACK_COLORS = {
  neutral50: '#FBFBFB',
  neutral200: '#E1E1E1',
  neutral400: '#C3C3C3',
  neutral600: '#989898',
  neutral700: '#727272',
  neutral900: '#252525',
  primary100: '#141414',
  primary300: '#141414',
  primary500: '#141414',
  primary700: '#141414',
  primary900: '#141414',

  secondary100: '#d7787f',
  secondary300: '#ca4b54',
  secondary500: '#ad2025',
  secondary700: '#84151d',
  secondary900: '#5f0f15',

  tertiary100: '#80afc6',
  tertiary300: '#337fa4',
  tertiary500: '#005f8d',
  tertiary700: '#004363',
  tertiary900: '#002638',

  grey100: '#fcfcfc',
  grey150: '#f9f9f9',
  grey200: '#f6f6f6',
  grey300: '#e8e8e8',
  grey500: '#b4b4b4',
  grey600: 'rgba(74, 74, 74, 0.48)',
  grey700: '#727272',
  grey750: '#585858',
  grey800: '#434343',
  grey900: '#252525',

  fullBlack: '#000',
  newGray300: '#f0f0f0',
  modalBackground: '#f2f0f0',

  // opaque colors
  black3: 'rgba(20,20,20, 0.03)',
  black5: 'rgba(20,20,20, 0.05)',
  black10: 'rgba(20,20,20, 0.1)',
  black20: 'rgba(20,20,20, 0.2)',
  black40: 'rgba(20,20,20, 0.4)',
  black60: 'rgba(20,20,20, 0.6)',
  black80: 'rgba(20,20,20, 0.8)',
  black: 'rgba(20,20,20)',

  white3: 'rgba(255, 255,255, 0.03)',
  white10: 'rgba(255, 255,255, 0.1)',
  white20: 'rgba(255, 255,255, 0.2)',
  white40: 'rgba(255, 255,255, 0.4)',
  white60: 'rgba(255, 255,255, 0.6)',
  white80: 'rgba(255, 255,255, 0.8)',
  white: 'rgba(255,255,255)',

  // support colors
  supportSuccess: '#109877',
  supportSuccess300: '#70DEC3',
  supportError: '#d03a3a',
  supportWarn: '#fab900',
  supportInfo: '#009ae5',
  supportTabBarOn: '#e3232a',

  // figma-declared structure
  support1: '#009ae5',
  support2: '#e3232a',
  support3: '#205C40',

  supportCta: '#205c40',

  gradientTertiary:
    'linear-gradient(270deg, rgba(8, 60, 198, 0) 0%, #c3c3c3 100%)',
};

const jackFontPrimary: FontType = {
  name: 'proxima-nova',
  genericFamilyName: 'sans-serif',
  variable: false,
  settings: {
    thin: {
      /** MISSING  */
      weight: '100',
    },
    extraThin: {
      /** MISSING  */
      weight: '200',
    },
    light: {
      /** MISSING  */
      weight: '300',
    },
    regular: {
      src: fontPath(ProximaNova, 'woff2'),
      weight: '400',
    },
    medium: {
      /** MISSING  */
      weight: '500',
    },
    semiBold: {
      /** MISSING  */
      weight: '600',
    },
    bold: {
      src: fontPath(ProximaNovaBold, 'woff2'),
      weight: '700',
    },
    extraBold: {
      src: fontPath(ProximaNovaExtrabold, 'woff2'),
      weight: '800',
    },
    black: {
      /** MISSING  */
      weight: '900',
    },
    condensedMedium: {
      /** MISSING  */
      weight: '500',
      stretch: 'condensed',
    },
    condensedSemiBold: {
      /** MISSING  */
      weight: '600',
      stretch: 'condensed',
    },
    condensedBold: {
      /** MISSING  */
      weight: '700',
      stretch: 'condensed',
    },
    condensedExtraBold: {
      /** MISSING  */
      weight: '800',
      stretch: 'condensed',
    },
  },
};

const jackFontSecondary: FontType = {
  name: 'DINPro',
  genericFamilyName: 'sans-serif',
  variable: false,
  settings: {
    thin: {
      /** MISSING  */
      weight: '100',
    },
    extraThin: {
      /** MISSING  */
      weight: '200',
    },
    light: {
      /** MISSING  */
      weight: '300',
    },
    regular: {
      /** MISSING  */
      weight: '400',
    },
    medium: {
      /** MISSING  */
      weight: '500',
    },
    semiBold: {
      /** MISSING  */
      weight: '600',
    },
    bold: {
      /** MISSING  */
      weight: '700',
    },
    extraBold: {
      src: fontPath(DINBoldOTF, 'opentype'),
      weight: '800',
    },
    black: {
      /** MISSING  */
      src: fontPath(DINBlackTTF, 'opentype'),
      weight: '900',
    },
    condensedMedium: {
      /** MISSING  */
      weight: '500',
      stretch: 'condensed',
    },
    condensedSemiBold: {
      /** MISSING  */
      weight: '600',
      stretch: 'condensed',
    },
    condensedBold: {
      /** MISSING  */
      weight: '700',
      stretch: 'condensed',
    },
    condensedExtraBold: {
      /** MISSING  */
      weight: '800',
      stretch: 'condensed',
    },
  },
};

export const jackPreset = new Theme()
  .setFontPrimary(jackFontPrimary)
  .setFontSecondary(jackFontSecondary)
  .setColor('background', JACK_COLORS.newGray300)
  .setColor('neutral700', JACK_COLORS.neutral700)
  .setColor('neutral900', JACK_COLORS.neutral900)
  .setColor('primary100', JACK_COLORS.secondary100)
  .setColor('primary300', JACK_COLORS.secondary300)
  .setColor('primary500', JACK_COLORS.secondary500)
  .setColor('primary700', JACK_COLORS.secondary700)
  .setColor('primary900', JACK_COLORS.secondary900)
  .setColor('secondary100', JACK_COLORS.primary100)
  .setColor('secondary300', JACK_COLORS.primary300)
  .setColor('secondary500', JACK_COLORS.primary500)
  .setColor('secondary700', JACK_COLORS.primary700)
  .setColor('secondary900', JACK_COLORS.primary900)
  .setColor('tertiary100', JACK_COLORS.tertiary100)
  .setColor('tertiary300', JACK_COLORS.tertiary300)
  .setColor('tertiary500', JACK_COLORS.tertiary500)
  .setColor('tertiary700', JACK_COLORS.tertiary700)
  .setColor('tertiary900', JACK_COLORS.tertiary900)
  .setColor('gray200', JACK_COLORS.grey200)
  .setColor('gray300', JACK_COLORS.grey300)
  .setColor('gray500', JACK_COLORS.grey500)
  .setColor('gray600', JACK_COLORS.grey600)
  .setColor('gray700', JACK_COLORS.grey700)
  .setColor('support1', JACK_COLORS.support1)
  .setColor('support2', JACK_COLORS.support2)
  .setColor('support3', JACK_COLORS.support3)
  .setColor('error', JACK_COLORS.supportError)
  .setColor('success', JACK_COLORS.supportSuccess)
  .setColor('info', JACK_COLORS.supportInfo)
  .setColor('verticalNavMenuBg', 'transparent')
  .setColor('verticalNavHeadingColor', JACK_COLORS.black)
  .setColor('verticalNavIconColor', JACK_COLORS.grey700)
  .setColor('verticalNavTextColor', JACK_COLORS.grey700)
  .setColor('verticalNavNoIconColor', JACK_COLORS.grey200)
  .setColor('verticalNavNoIconBackground', JACK_COLORS.grey700)
  .setColor('verticalNavOverlayBg', JACK_COLORS.newGray300)
  .setColor('verticalNavItemSelected', JACK_COLORS.grey200)
  .setColor('verticalNavItemHoverBg', JACK_COLORS.grey200)
  .setColor('verticalNavSkeletonBg', 'transparent')
  .setColor(
    'verticalNavSkeletonBgImg',
    createSkeletonColorsTransition(
      JACK_COLORS.grey300,
      JACK_COLORS.newGray300,
      JACK_COLORS.grey300,
    ),
  )
  .setColor('headerBg', JACK_COLORS.white)
  .setColor('headerTextColor', JACK_COLORS.grey700)
  .setColor('headerTextColorSelected', JACK_COLORS.primary500)
  .setColor('headerUnderlineSelected', JACK_COLORS.secondary500)
  .setColor('subHeaderBg', 'transparent')
  .setColor('subHeaderTextColor', 'gray')
  .setColor('moduleHeaderBg', JACK_COLORS.primary500)
  .setColor('moduleHeaderColor', JACK_COLORS.grey300)
  .setColor('playContentTextColor', JACK_COLORS.grey500)
  .setColor('playContentActiveColor', JACK_COLORS.fullBlack)
  .setColor('betslipSummaryBg', JACK_COLORS.grey900)
  .setColor('betslipBetItemColor', JACK_COLORS.primary100)
  .setColor('betslipTotalStakeColor', JACK_COLORS.grey500)
  .setColor('betslipQuickBetsBg', JACK_COLORS.secondary500)
  .setColor('topLevelNavBg', JACK_COLORS.black)
  .setColor('topLevelNavItemColor', JACK_COLORS.grey500)
  .setColor('modalBackground', JACK_COLORS.modalBackground)

  .setColor('successIconForeground', JACK_COLORS.white)
  .setColor('successIconBackground', JACK_COLORS.supportSuccess)

  .setColor('betslipStakeInputText', JACK_COLORS.primary700)
  .setColor(
    'topLevelNavItemSelectedBg',
    'linear-gradient(rgba(173, 32, 37, 0.4), rgba(173, 32, 37, 0))',
  )
  .setColor('gradientTertiary', JACK_COLORS.gradientTertiary)
  .setComponentProperty('noContentMessage', ({ comp }) => ({
    ...comp,
    titleColorDark: JACK_COLORS.fullBlack,
    bodyColorDark: JACK_COLORS.fullBlack,
  }))
  .setComponentProperty('outcome', ({ comp, baseTheme }) => ({
    ...comp,
    backgroundSelectedColor: JACK_COLORS.primary100,
    labelBackgroundColor: JACK_COLORS.primary500,
    labelFontSize: baseTheme.typography.textXxs2,
    borderColor: baseTheme.defaultColors.gray500,
    borderDisabledColor: baseTheme.defaultColors.gray500,
    labelColor: baseTheme.defaultColors.black,
    labelSelectedColor: baseTheme.defaultColors.white,
    labelFont: baseTheme.getFontStyle('primary', 'regular'),
    oddsPaddingTop: baseTheme.sizes.spacingXs,
    oddsPaddingBottom: baseTheme.sizes.spacingXxs,
    outcomeLabelPaddingTop: baseTheme.sizes.spacingXxs,
    labelLineHeight: 1.65,
  }))
  .setComponentProperty('outcomeV2', ({ comp, baseTheme }) => ({
    ...comp,
    fontColor: JACK_COLORS.primary500,
    oddsFontColor: JACK_COLORS.primary500,
    containerBorderRadius: baseTheme.sizes.borderRadiusSm,
    containerSelectedBackground: baseTheme.defaultColors.success700,
    containerSelectedBorderColor: baseTheme.defaultColors.success900,
    oddsFont: baseTheme.getFontStyle('secondary', 'bold'),
    oddsFontSize: '15px',
    oddsLineHeight: '16px',

    oddsTwoLinesFontSize: '15px',
    oddsTwoLinesLineHeight: '16px',
  }))
  .setComponentProperty('offeringsV2', ({ comp, baseTheme }) => ({
    ...comp,
    defaultBorderRadius: baseTheme.sizes.borderRadiusSm,
    alternateBorderRadius: baseTheme.sizes.borderRadiusSm,
    scoreFontColor: JACK_COLORS.primary500,
    totalScoreBackgroundColor: JACK_COLORS.secondary500,
    totalScoreFontColor: baseTheme.defaultColors.shadeWhite,

    headerLiveBackgroundColor: JACK_COLORS.secondary500,
    headerLiveFontColor: baseTheme.defaultColors.shadeWhite,
    headerTopBackgroundColor: JACK_COLORS.primary500,
    headerTopFontColor: baseTheme.defaultColors.shadeWhite,
    headerDefaultBackgroundColor: baseTheme.defaultColors.neutral900,
    headerDefaultFontColor: baseTheme.defaultColors.shadeWhite,

    labelHeaderLabelsFontColor: baseTheme.defaultColors.shadeWhite,

    sectionLabelHeaderBackgroundColor: baseTheme.defaultColors.neutral200,
    sectionLabelHeaderFontColor: baseTheme.defaultColors.neutral900,

    expandButtonBackgroundColor: baseTheme.defaultColors.neutral50,
    expandButtonBorderColor: baseTheme.defaultColors.neutral500,
    expandButtonFontColor: JACK_COLORS.primary500,

    expandButtonHoverBackgroundColor: baseTheme.defaultColors.neutral100,
    expandButtonHoverBorderColor: baseTheme.defaultColors.neutral500,
    expandButtonHoverFontColor: JACK_COLORS.primary500,

    expandButtonActiveBackgroundColor: JACK_COLORS.secondary500,
    expandButtonActiveBorderColor: JACK_COLORS.secondary500,
    expandButtonActiveFontColor: baseTheme.defaultColors.shadeWhite,

    'offerings/fg/default': JACK_COLORS.secondary500,
  }))
  .setComponentProperty('outcomeList', ({ comp, baseTheme }) => ({
    ...comp,
    titleColor: baseTheme.defaultColors.gray500,
    titleFont: baseTheme.getFontStyle('primary', 'bold'),
    extraOutcomesFont: baseTheme.getFontStyle('primary', 'bold'),
  }))
  .setComponentProperty('board', ({ comp, baseTheme }) => ({
    ...comp,
    headerPrimaryBackgroundColor: JACK_COLORS.primary500,
    headerSecondaryBackgroundColor: JACK_COLORS.primary500,
    headerPrimaryForegroundColor: JACK_COLORS.white,
    headerSecondaryForegroundColor: JACK_COLORS.white,
    headerFontSize: baseTheme.typography.textXs,
    subHeaderBackgroundColor: baseTheme.defaultColors.gray300,
    subHeaderForegroundColor: baseTheme.defaultColors.gray700,
    headerTitleFont: baseTheme.getFontStyle('primary', 'extraBold'),
    subHeaderFont: baseTheme.getFontStyle('primary', 'bold'),
  }))
  .setComponentProperty('slider', ({ comp }) => ({
    ...comp,
    baseValueColor: JACK_COLORS.secondary500,
    thumbTextColor: JACK_COLORS.primary500,
    thumbArrowsColor: JACK_COLORS.secondary500,
  }))
  .setComponentProperty('linePair', ({ comp, baseTheme }) => ({
    ...comp,
    collapseButtonColor: JACK_COLORS.primary500,
    collapseButtonFontColor: baseTheme.defaultColors.white,
  }))
  .setComponentProperty('eventInfo', ({ comp, baseTheme }) => ({
    ...comp,
    playerTextColor: JACK_COLORS.neutral900,
    eventGroupHeaderFont: baseTheme.getFontStyle('primary', 'bold'),
    detailsTextColor: JACK_COLORS.neutral900,
    detailsBorderRadius: baseTheme.sizes.borderRadiusSm,

    scoreboardBackgroundColor: JACK_COLORS.neutral50,
    scoreboardSeparatorColor: JACK_COLORS.secondary500,
    scoreboardScoresBackgroundColor: JACK_COLORS.neutral900,
    scoreboardPeriodScoreHighlightColor: JACK_COLORS.white,
    scoreboardPeriodScoreDisabledColor: JACK_COLORS.neutral700,
    scoreboardPeriodScoreSeparatorColor: JACK_COLORS.neutral400,

    extraInfoTextColor: JACK_COLORS.black,
    liveBadgeBackgroundColor: JACK_COLORS.white,
    liveBadgeFontColor: JACK_COLORS.secondary500,
    subHeaderBackground: JACK_COLORS.grey300,
    subHeaderTextColor: JACK_COLORS.grey800,
    tabBarTextTransform: 'none',
    tabItemBorderRadius: '20px',
  }))
  .setComponentProperty('pillTabBar', ({ comp }) => ({
    ...comp,
    tabBarTextTransform: 'none',
    tabBarItemBorderRadius: '20px',
    selectedTabBarTextBackground: JACK_COLORS.primary500,
    tabBarInactiveItemColor: JACK_COLORS.primary500,
    tabBarTextBackground: JACK_COLORS.neutral200,
    textColor: JACK_COLORS.black,
  }))
  .setComponentProperty('iconButton', ({ comp }) => ({
    ...comp,
    secondaryBackgroundColor: JACK_COLORS.primary500,
    secondaryColor: JACK_COLORS.white,
  }))
  .setComponentProperty('verticalNavigationMenu', ({ comp, baseTheme }) => ({
    ...comp,
    backgroundColor: JACK_COLORS.black,
    verticalIconColor: JACK_COLORS.white,
    verticalTextColor: JACK_COLORS.white,
    verticalItemHoverBgColor: JACK_COLORS.grey900,
    verticalTitleColor: JACK_COLORS.grey700,
    verticalLabelFont: baseTheme.getFontStyle('primary', 'bold'),
  }))
  .setComponentProperty('horizontalNavigationMenu', ({ comp }) => ({
    ...comp,
    textColor: JACK_COLORS.primary700,
    iconColor: JACK_COLORS.primary700,
  }))
  .setComponentProperty('articleCard', ({ comp }) => ({
    ...comp,
    titleColor: JACK_COLORS.grey900,
    summaryColor: JACK_COLORS.grey800,
    authorColor: JACK_COLORS.grey800,
  }))
  .setComponentProperty('accountMenu', ({ comp, baseTheme }) => ({
    ...comp,
    headerBg: baseTheme.defaultColors.primary500,
  }))
  .setComponentProperty('form', ({ comp, baseTheme }) => ({
    ...comp,
    subTitleColor: JACK_COLORS.fullBlack,
    titleColor: JACK_COLORS.fullBlack,
    titleFont: baseTheme.getFontStyle('primary', 'extraBold'),
    subTitleFont: baseTheme.getFontStyle('primary', 'regular'),
    bg: JACK_COLORS.grey150,
    titleTextTransform: 'uppercase',
  }))
  .setComponentProperty('balanceSheet', ({ comp }) => ({
    ...comp,
    withdrawButtonBg: JACK_COLORS.supportCta,
    buttonHoverColor: JACK_COLORS.secondary300,
  }))
  .setComponentProperty('tabs', ({ comp }) => ({
    ...comp,
    alternativeTabBg: JACK_COLORS.grey750,
    alternativeColor: JACK_COLORS.grey300,
  }))
  .setComponentProperty('platformHeaderNavigation', ({ comp }) => ({
    ...comp,
    navItemAlignmentPaddingTop: '2px',
  }))
  .setComponentProperty('platformBreadcrumbs', ({ comp }) => ({
    ...comp,
    itemSeparatorColor: JACK_COLORS.secondary500,
    itemTrailingColor: JACK_COLORS.neutral600,
    itemColor: JACK_COLORS.neutral600,
    itemActiveColor: JACK_COLORS.primary500,
  }))
  .setComponentProperty('platformSideNavigation', ({ comp }) => ({
    ...comp,
    itemColor: JACK_COLORS.grey800,
    itemBackgroundActiveState: JACK_COLORS.black5,
    fallbackIconLineHeight: '1em',
    fallbackIconBackground: JACK_COLORS.grey800,
  }))
  .setComponentProperty(
    'platformSubLevelNavigation',
    ({ comp, baseTheme }) => ({
      ...comp,
      itemWrapperHoverBackground: JACK_COLORS.black5,
      itemBorderRadius: baseTheme.sizes.borderRadiusXl,
      slimItemBorderRadius: '20px',
      slimItemLabelColor: JACK_COLORS.black,
      slimItemLabelColorHover: JACK_COLORS.black,
      slimItemBackground: JACK_COLORS.grey300,
      slimItemBackgroundHover: JACK_COLORS.grey500,
      slimItemLabelColorActive: JACK_COLORS.white,
      slimItemBackgroundActive: JACK_COLORS.black,
      dropdownContentItemHover: JACK_COLORS.grey200,
    }),
  )
  .setComponentProperty('outcome', ({ comp }) => ({
    ...comp,
    labelLineHeight: 1,
    outcomeHeight: '26px',
  }))
  .setComponentProperty('roundedButton', ({ comp }) => ({
    ...comp,
    alignPaddingTop: '1px',
  }))
  .setComponentProperty('verticalNavigation', ({ comp }) => ({
    ...comp,
    textTransform: 'none',
  }))
  .setComponentProperty('select', ({ comp }) => ({
    ...comp,
    boxShadow: '0px 4px 24px 0px rgba(20, 0, 0, 0.15)',
  }))
  .setComponentProperty('button', ({ comp }) => ({
    ...comp,
    disabledOpacity: '1',
    disabledBgColor: JACK_COLORS.grey500,
    submitButtonColor: JACK_COLORS.secondary500,
    primaryBgColor: JACK_COLORS.primary500,
    primaryLabelColor: JACK_COLORS.white,
    secondaryBgColor: JACK_COLORS.secondary500,
    secondaryLabelColor: JACK_COLORS.white,
  }))
  .setSize('highlightsCarouselItemWidth', '295px')
  .setSize('highlightsCarouselItemWidthLg', '306px')
  .setComponentProperty('modal', ({ comp, baseTheme }) => ({
    ...comp,
    position: 'left',
    padding: '5px',
    color: JACK_COLORS.primary500,
    backgroundColor: JACK_COLORS.modalBackground,
    closeButtonBackground: baseTheme.defaultColors.neutral200,
    closeButtonBorderRadius: baseTheme.sizes.borderRadiusSm,
    closeButtonMarginTop: baseTheme.sizes.spacing4,
    closeButtonMarginHorizontal: baseTheme.sizes.spacing4,
    closeButtonFontSize: '18px',
  }))
  .setComponentProperty('betslip', ({ comp, baseTheme }) => ({
    ...comp,
    oddsChangeDropdownBackground: JACK_COLORS.grey900,

    betKeyboardButtonColor: JACK_COLORS.white,
    bottomColorPlaceButton: JACK_COLORS.primary500,
    bottomBgPlaceButtonDisabled: baseTheme.defaultColors.neutral800,
    bottomBgDefault: JACK_COLORS.primary500,

    quickBetButtonColor: JACK_COLORS.primary500,
    combinationFoldsColor: JACK_COLORS.primary500,
    outcomeLabelBg: JACK_COLORS.primary500,
    outcomeOddsLabelColor: baseTheme.defaultColors.white,
  }))
  .setComponentProperty('betslipReceipt', ({ comp, baseTheme }) => ({
    ...comp,
    footerButtonBackground: baseTheme.defaultColors.black,
    footerRoundedButtonColor: baseTheme.defaultColors.primary500,
  }))
  .setComponentProperty('iconMessage', ({ comp }) => ({
    ...comp,
    iconBackgroundColorSuccess: 'success700',
  }))
  .setComponentProperty('statusInfo', ({ comp, baseTheme }) => ({
    ...comp,
    iconContainerBackgroundColorWarning: 'alert300',
    iconWarnColor: 'alert500',
    buttonColor: baseTheme.defaultColors.black,
  }))
  .setComponentProperty('payment', ({ comp, baseTheme }) => ({
    ...comp,
    deleteStoredPaymentMethodActiveBackgroundColor:
      baseTheme.defaultColors.primary700,
  }))
  .getTheme();
